<template>
  <div>
    <b-modal ref="invite-member"
             hide-footer
             no-close-on-backdrop
             :title="$t('organization.invite')">
      <invite-organization-member :workspace-id="getWorkspaceId"
                                  @close="$refs['invite-member'].hide()"/>
    </b-modal>

    <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="warning"
              class="rounded-circle mr-1"
              @click="inviteByMail">
      <div class="icon-wrapper">
        <feather-icon icon="PlusIcon" size="21"/>
      </div>
    </b-button>

    <b-row class="mt-2">
      <b-table :items="invitations"
               :fields="fields"
               class="rounded-bottom">
        <template #head(email)>
          <span>{{ $t('table.email') }}</span>
        </template>

        <template #head(invitedBy)>
          <span>{{ $t('table.invitedBy') }}</span>
        </template>

        <template #head(invitedAt)>
          <span>{{ $t('table.invitedAt') }}</span>
        </template>

        <template #head(completed)>
          <span>{{ $t('table.isCompleted') }}</span>
        </template>

        <template #cell(email)="data">
          {{ data.value }}
        </template>

        <template #cell(invitedBy)="data">
          {{ data.value }}
        </template>

        <template #cell(invitedAt)="data">
          <span v-b-tooltip.hover.bottom="getFormattedDate(data.value)">
            {{ getFromNow(data.value) }}
          </span>
        </template>

        <template #cell(completed)="data">
          {{ data.value ? $t('accepted') : $t('waiting') }}
        </template>
      </b-table>
    </b-row>
  </div>
</template>

<script>
import {BButton, BModal, BRow, BTable, VBTooltip} from "bootstrap-vue";
import {mapState} from "vuex";
import InviteOrganizationMember from './Invite/index';
import Ripple from "vue-ripple-directive";
import DateUtil from "@/services/DateUtil";

export default {
  name: "Invitations",
  computed: {
    getWorkspaceId() {
      return this.$route.params.id;
    },
    ...mapState({
      invitations: state => state.workspaces.invitedMembers
    })
  },
  components: {
    BModal,
    BRow,
    BButton,
    BTable,
    InviteOrganizationMember
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  created() {
    this.fetchInvitations()
  },
  data() {
    return {
      fields: [
        {key: 'email'},
        {key: 'invitedBy'},
        {key: 'invitedAt'},
        {key: 'completed'},
      ],
    }
  },

  methods: {
    fetchInvitations() {
      this.$store.dispatch('workspaces/fetchInvitations', this.getWorkspaceId)
    },
    inviteByMail() {
      this.$refs['invite-member'].show()
    },

    getFromNow(date) {
      return DateUtil.fromNow(date);
    },
    getFormattedDate(date) {
      return DateUtil.getFormattedTime(date);
    },
  }
}
</script>

<style scoped>

</style>