<template>
  <div>
    <validation-observer
        ref="newGroupForm"
        #default="{invalid}">
      <b-form @submit.prevent>
        <b-col cols="12">
          <b-form-group :label="$t('group.name')"
                        label-for="fh-doc-name">
            <validation-provider #default="{ errors }"
                                 name="Title"
                                 vid="title"
                                 rules="required">
              <b-form-input v-model="title"
                            id="fh-doc-name"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('group.context')"/>
              <small v-if="errors.length > 0" class="text-danger">
                {{ $t('required') }}
              </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group class="mt-1"
                        :label="$t('group.enterDescription')"
                        label-for="fh-doc-type">
            <validation-provider #default="{ errors }"
                                 name="Description"
                                 vid="description"
                                 rules="required">
              <b-form-textarea v-model="description"
                               :placeholder="$t('group.description')"/>
              <small v-if="errors.length > 0" class="text-danger">
                {{ $t('required') }}
              </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <br/>
          <b-row align-h="end" class="px-1">
            <b-button @click="addGroup"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      :disabled="invalid"
                      variant="primary">
              {{ $t('buttons.save') }}
            </b-button>
          </b-row>
        </b-col>
      </b-form>

    </validation-observer>
  </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {BButton, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BRow} from "bootstrap-vue";
import {required} from '@validations'
import Ripple from 'vue-ripple-directive'
import {GroupEventTypes, PEventBus} from "@/services/PEventBus";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";


export default {
  name: 'add-group',
  components: {
    BForm,
    BFormInput,
    BFormTextarea,
    BButton,
    BCol,
    BFormGroup,
    BRow,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple
  },
  computed: {
    getWorkspaceId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      invalid: false,
      title: null,
      description: null,

      required,
    }
  },
  methods: {
    addGroup() {
      this.$refs.newGroupForm.validate()
          .then(success => {
            if (success) {
              const payload = {
                request: {
                  name: this.title,
                  description: this.description,
                  organizationId: this.getWorkspaceId
                }
              }
              this.$store.dispatch('group/create', payload)
                  .then(() => {
                    PEventBus.$emit(GroupEventTypes.GROUP_CREATED)
                    this.notify(i18n.t('messages.group.added'))
                  })
                  .catch(err => {
                    this.fail(err.errorCode === 5003
                        ? i18n.t('messages.notAuthorized')
                        : i18n.t("messages.errorOccurred"))
                  });
            }
          })
          .then(() => this.fetchGroupsAndMembers())
    },
    notify(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "info",
              icon: "CheckIcon",
            },
          },
          {position}
      );
    },
    fail(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "danger",
              icon: "AlertIcon",
            },
          },
          {position})
    },
  }
}
</script>
