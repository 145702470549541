var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"supplierForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{attrs:{"align-h":"start"}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('company.setSupplier'),"label-for":"supplier-name"}},[_c('validation-provider',{attrs:{"name":"SupplierName","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supplier-name","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('company.name')},model:{value:(_vm.supplierName),callback:function ($$v) {_vm.supplierName=$$v},expression:"supplierName"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('required'))+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('company.emailWhoSupplierSignatory'),"label-for":"supplier-email"}},[_c('validation-provider',{attrs:{"name":"SupplierEmail","vid":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supplier-email","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('company.responsibleEmail')},model:{value:(_vm.supplierEmail),callback:function ($$v) {_vm.supplierEmail=$$v},expression:"supplierEmail"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(_vm.$t('messages.company.emailRequired'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('company.phoneNumber'),"label-for":"supplier-phone"}},[_c('validation-provider',{attrs:{"name":"SupplierPhone","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supplier-phone","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('company.phone')},model:{value:(_vm.supplierPhone),callback:function ($$v) {_vm.supplierPhone=$$v},expression:"supplierPhone"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(_vm.$t('messages.company.phoneRequired'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('company.taxNumber'),"label-for":"supplier-tax"}},[_c('validation-provider',{attrs:{"name":"SupplierTax","vid":"tax","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supplier-tax","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('company.setTaxNumber')},model:{value:(_vm.supplierTax),callback:function ($$v) {_vm.supplierTax=$$v},expression:"supplierTax"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(_vm.$t('messages.company.taxRequired'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('company.mersisNumber'),"label-for":"supplier-mersis"}},[_c('validation-provider',{attrs:{"name":"SupplierMersis","vid":"mersis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supplier-mersis","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('company.setMersisNumber')},model:{value:(_vm.supplierMersis),callback:function ($$v) {_vm.supplierMersis=$$v},expression:"supplierMersis"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(_vm.$t('messages.company.mersisRequired'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('company.registryNumber'),"label-for":"supplier-registry"}},[_c('validation-provider',{attrs:{"name":"SupplierRegistry","vid":"registry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supplier-registry","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('company.setRegistryNumber')},model:{value:(_vm.supplierRegistry),callback:function ($$v) {_vm.supplierRegistry=$$v},expression:"supplierRegistry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(_vm.$t('messages.company.registryRequired'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('company.address'),"label-for":"supplier-address"}},[_c('validation-provider',{attrs:{"name":"SupplierAddress","vid":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3","id":"supplier-address","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('company.setAddress')},model:{value:(_vm.supplierAddress),callback:function ($$v) {_vm.supplierAddress=$$v},expression:"supplierAddress"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(_vm.$t('messages.company.addressRequired'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('br'),_c('b-row',{staticClass:"px-1",attrs:{"align-h":"end"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","disabled":invalid,"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }