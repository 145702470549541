<template>
  <div>
    <b-breadcrumb class="breadcrumb-chevron mb-1 justify-content-start align-items-center">
      <b-breadcrumb-item to="/workspaces">
        <feather-icon icon="GridIcon"
                      size="18"
                      class="align-text-bottom"/>
      </b-breadcrumb-item>
      <b-breadcrumb-item to="/workspaces">
        <span class="font-medium-2 font-weight-bold">{{ $t('organization.organizations') }}</span>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>
        <span class="font-medium-2">{{ workspace.name }}</span>
      </b-breadcrumb-item>
    </b-breadcrumb>

    <b-tabs content-class="col-12 mt-1 mt-md-0"
            pills>

      <!-- general tab -->
      <b-tab :active="this.$route.params.tab === '1'">

        <!-- title -->
        <template #title>
          <feather-icon icon="InfoIcon"
                        size="18"
                        class="mr-50"/>
          <span class="font-weight-bold">{{ $t('organization.details') }}</span>
        </template>

        <workspace-detail/>
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab :active="this.$route.params.tab === '2'">

        <!-- title -->
        <template #title>
          <feather-icon icon="UsersIcon"
                        size="18"
                        class="mr-50"/>
          <span class="font-weight-bold">{{ $t('organization.groupsAndUsers') }}</span>
        </template>

        <groups-and-users/>
      </b-tab>
      <!--/ change password tab -->

      <!-- info -->
      <b-tab :active="this.$route.params.tab === '3'">

        <!-- title -->
        <template #title>
          <feather-icon icon="SendIcon"
                        size="18"
                        class="mr-50"/>
          <span class="font-weight-bold">{{ $t('organization.invitedOnes') }}</span>
        </template>

        <invitations/>
      </b-tab>

      <b-tab :active="this.$route.params.tab === '4'">

        <!-- title -->
        <template #title>
          <feather-icon icon="PenToolIcon"
                        size="18"
                        class="mr-50"/>
          <span class="font-weight-bold">{{ $t('organization.suppliers') }}</span>
        </template>
        <company-list :workspace-id="getWorkspaceId"/>
      </b-tab>

      <!-- social links -->
<!--      <b-tab :active="this.$route.params.tab === '5'">-->

<!--        &lt;!&ndash; title &ndash;&gt;-->
<!--        <template #title>-->
<!--          <feather-icon icon="BellIcon"-->
<!--                        size="18"-->
<!--                        class="mr-50"/>-->
<!--          <span class="font-weight-bold">{{ $t('organization.notifications') }}</span>-->
<!--        </template>-->

<!--        <notifications/>-->
<!--      </b-tab>-->

      <!-- notification -->
<!--      <b-tab :active="this.$route.params.tab === '6'">-->

<!--        &lt;!&ndash; title &ndash;&gt;-->
<!--        <template #title>-->
<!--          <feather-icon icon="SettingsIcon"-->
<!--                        size="18"-->
<!--                        class="mr-50"/>-->
<!--          <span class="font-weight-bold">{{ $t('organization.settings') }}</span>-->
<!--        </template>-->

<!--        <settings/>-->
<!--      </b-tab>-->
    </b-tabs>
  </div>
</template>

<script>
import {BBreadcrumb, BBreadcrumbItem, BTab, BTabs} from "bootstrap-vue";
import WorkspaceDetail from "@/views/Workspace/WorkspaceDetail";
import GroupsAndUsers from "@/views/Workspace/GroupsAndUsers";
import Invitations from "@/views/Workspace/Invitations";
import Notifications from "@/views/Workspace/Notifications";
import Settings from "@/views/Workspace/Settings";
import {mapState} from "vuex";
import CompanyList from "@/views/Companies/List/CompanyList";

export default {
  name: "Workspace",
  components: {
    // Settings,
    // Notifications,
    Invitations,
    GroupsAndUsers,
    BTabs,
    BTab,
    BBreadcrumb, BBreadcrumbItem,
    WorkspaceDetail,
    CompanyList
  },
  data() {
    return {
      options: {},
    }
  },
  computed: {
    getWorkspaceId() {
      return this.$route.params.id;
    },
    ...mapState({
      workspace: state => state.workspaces.organization
    })
  },
  created() {
    this.fetchWorkspace()
  },
  methods: {
    fetchWorkspace() {
      this.$store.dispatch('workspaces/fetchById', this.getWorkspaceId)
    }
  }
}
</script>

<style scoped>

</style>