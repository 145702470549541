<template>
  <div>
    Ayarlar
  </div>
</template>

<script>
export default {
  name: "Settings",
  computed: {
    getWorkspaceId() {
      return this.$route.params.id;
    },
  },
}
</script>

<style scoped>

</style>