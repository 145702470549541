<template>
  <div>
    <loading v-if="loading"></loading>
    <div v-show="!loading">


      <b-modal ref="add-group"
               hide-footer
               no-close-on-backdrop
               :title="$t('group.add')">
        <add-group/>
      </b-modal>

      <b-modal ref="edit-group"
               hide-footer
               no-close-on-backdrop
               :title="$t('group.edit')">
        <edit-group :selected-group="selectedGroup"/>
      </b-modal>

      <b-modal ref="add-group-member"
               hide-footer
               no-close-on-backdrop
               :title="$t('group.addUser')">
        <add-group-member :workspace-id="getWorkspaceId" :group-id="selectedGroupId"/>
      </b-modal>

      <b-modal ref="edit-group-member"
               hide-footer
               no-close-on-backdrop
               :title="$t('group.editMember')">
        <edit-group-member :user-member="selectedUserMember" :group-id="selectedGroupId"/>
      </b-modal>

      <b-row v-if="workspaceMembers.length !== 0">
        <b-col cols="12"
               md="6"
               class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="warning"
                    class="rounded-circle mr-1"
                    @click="showAddGroupModal">
            <div class="icon-wrapper">
              <feather-icon icon="PlusIcon" size="21"/>
            </div>
          </b-button>
        </b-col>
        <!-- Search -->
        <b-col cols="6"
               md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input debounce="2000"
                          v-model="searchQuery"
                          class="d-inline-block mr-1"
                          :placeholder="$t('search')"/>
          </div>
        </b-col>
      </b-row>

      <Empty v-if="workspaceMembers.length === 0" @openModal="showAddGroupModal"/>

      <b-row v-for="(workspaceMember,ix) in workspaceMembers"
             :key="ix"
             class="mt-2">
        <b-col>
          <b-card>
            <b-row>
              <b-col>
                <b-card-title>
                  <div>
                    {{ workspaceMember.name }}
                  </div>
                  <small class="text-muted">
                    {{ workspaceMember.description }}
                  </small>
                </b-card-title>
              </b-col>

              <b-col class="justify-content-end align-content-end d-flex w-100">
                <b-row>
                  <b-col>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="submit"
                              :disabled="invalid"
                              variant="primary"
                              class="rounded-circle"
                              @click="showEditShowModal(workspaceMember)">
                      <feather-icon
                          icon="EditIcon"
                          size="15"/>
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="submit"
                              :disabled="invalid"
                              variant="danger"
                              class="rounded-circle"
                              @click="removeGroup(workspaceMember)">
                      <feather-icon
                          icon="TrashIcon"
                          size="15"/>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="match-height">
              <b-col md="3">
                <b-card border-variant="gray"
                        @click="showAddGroupMemberModal(workspaceMember.id)"
                        style="min-height: 160px; background-color: #091e420a"
                        class="cursor-pointer">
                  <h4 class="text-center"><strong>{{ $t('group.addMember') }}</strong></h4>
                </b-card>
              </b-col>

              <b-col v-for="(userMember,ix) in workspaceMember.users"
                     :key="ix"
                     md="3">
                <b-card border-variant="gray"
                        bg-variant="transparent"
                        style="min-height: 160px;"
                        class="justify-content-around var-card">
                  <b-row>
                    <b-col cols="9" class="justify-content-start">
                      <h4>{{ userMember.fullName }}</h4>
                      <span>{{ userMember.role.displayText }}</span>
                      <br/>
                      <br/>
                      <h5>{{ $t('organization.approveAuthority')}} : <span class="text-bold">{{ userMember.isApprover ?  $t('exists') : $t('notExists') }}</span></h5>
                      <h5>{{ $t('organization.signAuthority')}} : <span class="text-bold">{{ userMember.isSigner ? $t('exists') : $t('notExists') }}</span></h5>
                    </b-col>

                    <b-col cols="3" class="justify-content-end align-content-end d-flex">
                      <b-row class="justify-content-end">
                        <b-col>
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    :disabled="invalid"
                                    variant="primary"
                                    class="rounded-circle"
                                    @click="showEditGroupMemberModal(workspaceMember.id, userMember)">
                            <feather-icon
                                icon="EditIcon"
                                size="15"/>
                          </b-button>
                        </b-col>
                        <b-col>
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    :disabled="invalid"
                                    variant="danger"
                                    class="rounded-circle"
                                    @click="removeGroupMember(workspaceMember, userMember)">
                            <feather-icon
                                icon="XIcon"
                                size="15"/>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <div class="pt-2">
                    <small class="text-muted">
                      {{ userMember.email }}
                    </small>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-card>

        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import {BButton, BCard, BCardTitle, BCol, BFormInput, BRow} from 'bootstrap-vue'
import Loading from "@/views/components/Loading/Loading";
import Ripple from "vue-ripple-directive";
import {required} from '@validations'
import {GroupEventTypes, PEventBus} from "@/services/PEventBus";
import AddGroup from "@/views/Workspace/AddGroup";
import EditGroup from "@/views/Workspace/EditGroup";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import AddGroupMember from "@/views/Workspace/AddGroupMember";
import EditGroupMember from "@/views/Workspace/EditGroupMember";
import Empty from "@/views/Groups/List/Empty";

export default {
  name: "GroupsAndUsers",
  components: {
    AddGroupMember,
    EditGroupMember,
    AddGroup,
    EditGroup,
    Empty,
    BCard,
    BCardTitle,
    BCol,
    BRow,
    BButton,
    BFormInput,
    Loading
  },
  directives: {
    Ripple,
  },
  created() {
    this.fetchGroupsAndMembers()
  },
  computed: {
    getWorkspaceId() {
      return this.$route.params.id;
    },
    ...mapState({
      workspaceMembers: state => state.workspaces.organizationMembers,
      loading: state => state.workspaces.loading
    })
  },
  methods: {
    fetchGroupsAndMembers() {
      this.$store.dispatch('workspaces/fetchMembers', this.getWorkspaceId)
    },
    showAddGroupModal() {
      this.$refs["add-group"].show()
    },
    showEditShowModal(selectedGroup) {
      this.selectedGroup = selectedGroup
      this.$refs["edit-group"].show()
    },
    showAddGroupMemberModal(groupId) {
      this.selectedGroupId = groupId
      this.$refs["add-group-member"].show()
    },
    showEditGroupMemberModal(groupId, userMember) {
      this.selectedGroupId = groupId
      this.selectedUserMember = userMember
      this.$refs["edit-group-member"].show()
    },
    reloadGroups() {
      this.fetchGroupsAndMembers()
      this.$refs["add-group"].hide()
      this.$refs["add-group-member"].hide()
      this.$refs["edit-group"].hide()
    },
    removeGroup(selectedGroup) {
      this.$bvModal
          .msgBoxConfirm(this.$i18n.t('message.group.questionBeforeDelete'), {
            title: i18n.t('messages.areYouSure'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: i18n.t('yes'),
            cancelTitle: i18n.t('exit'),
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            centered: false,
          })
          .then(value => {
            if (value) {
              let icon = 'TrashIcon'
              this.$store.dispatch('group/delete', selectedGroup.id)
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: i18n.t('messages.notification'),
                        icon,
                        text: i18n.t('messages.group.deleted'),
                        variant: 'danger',
                      },
                    }, {position: 'top-center'})
                  })
                  .then(() => this.fetchGroupsAndMembers())
            }
          })
    },
    removeGroupMember(selectedGroup, selectedUser) {
      this.$bvModal
          .msgBoxConfirm(this.$i18n.t('messages.group.questionBeforeUserRemove'), {
            title: i18n.t('messages.areYouSure'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: i18n.t('yes'),
            cancelTitle: i18n.t('exit'),
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            centered: false,
          })
          .then(value => {
            if (value) {
              const payload = {
                groupId: selectedGroup.id,
                userId: selectedUser.id
              }

              this.$store.dispatch('group/removeUser', payload)
                  .then(() => {
                    PEventBus.$emit(GroupEventTypes.GROUP_UPDATED)
                    this.notify(i18n.t('messages.group.userRemoved'))
                  })
                  .catch(err => {
                    this.fail(err.errorCode === 5003
                        ? i18n.t('messages.notAuthorized')
                        : i18n.t("messages.errorOccurred"))
                  })
                  .then(() => this.fetchGroupsAndMembers())
            }
          })
    },
    notify(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "info",
              icon: "CheckIcon",
            },
          },
          {position}
      );
    },
    fail(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "danger",
              icon: "AlertIcon",
            },
          },
          {position})
    },
  },
  data() {
    return {
      invalid: false,
      groupTitle: null,
      groupDescription: null,
      selectedGroupId: null,
      selectedUserMember: null,

      required,

      selectedGroup: null,
      searchQuery: null
    }
  },
  mounted() {
    PEventBus.$on(GroupEventTypes.GROUP_CREATED, (evt) => this.reloadGroups())
    PEventBus.$on(GroupEventTypes.GROUP_UPDATED, (evt) => this.reloadGroups())
  },
  beforeDestroy() {
    PEventBus.$off(GroupEventTypes.GROUP_CREATED, (evt) => this.reloadGroups())
    PEventBus.$off(GroupEventTypes.GROUP_UPDATED, (evt) => this.reloadGroups())
  }
}
</script>

