<template>
  <b-col cols="12">
    <b-form-group class="mt-1"
                  label-for="fh-doc-type">

      <b-row class="mb-2">
        <b-col>
          {{ userMember.fullName }} / {{ userMember.email }}
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col class="w-100 text-center">
          <v-select :placeholder="$t('group.role')"
                    v-model="role"
                    label="displayText"
                    class="w-100 v-select-no-border"
                    :options="memberRoles">
          </v-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="w-100 text-left">
          <b-form-checkbox v-model="isApprover"
                           @click.native.stop>
            {{ $t('group.chooseAsApprover') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="w-100 text-left">
          <b-form-checkbox v-model="isSigner"
                           @click.native.stop>
            {{ $t('group.chooseAsSigner') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="justify-content-end align-content-end d-flex w-100">
          <b-button @click="update"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary">
            <span>{{ $t('buttons.save') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
</template>
<script>
import VSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import {BButton, BCol, BFormCheckbox, BFormGroup, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {GroupEventTypes, PEventBus} from "@/services/PEventBus";

export default {
  name: 'EditGroupMember',
  components: {
    VSelect,
    BCol,
    BRow,
    BButton,
    BFormCheckbox,
    BFormGroup
  },
  directives: {
    Ripple,
  },
  props: {
    groupId: String,
    userMember: Object
  },
  mounted() {
    this.isApprover = this.userMember.isApprover
    this.isSigner = this.userMember.isSigner
    this.role = this.memberRoles.filter(r => r.value === this.userMember.role.name)[0]
  },
  data() {
    return {
      memberRoles: [
        {
          displayText: i18n.t('roles.reader'),
          value: "READER"
        },
        {
          displayText: i18n.t('roles.editor'),
          value: "EDITOR"
        }
      ],
      isApprover: false,
      isSigner: false,
      role: null,
    }
  },
  methods: {
    update() {
      const payload = {
        groupId: this.groupId,
        userId: this.userMember.id,
        request: {
          role: this.role.name || this.role.value,
          isApprover: this.isApprover,
          isSigner: this.isSigner
        }
      }

      this.$store.dispatch('group/editUserGroup', payload)
          .then(() => {
            PEventBus.$emit(GroupEventTypes.GROUP_UPDATED)
            this.notify(i18n.t('messages.group.groupMemberUpdated'))
          })
          .catch(err => {
            this.fail(err.errorCode === 9011
                ? i18n.t('messages.group.groupShouldContainOneSigner')
                : err.errorCode === 5003
                    ? i18n.t('messages.notAuthorized')
                    : i18n.t("messages.errorOccurred"))
          })
    },
    notify(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "info",
              icon: "CheckIcon",
            },
          },
          {position}
      );
    },
    fail(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "danger",
              icon: "AlertIcon",
            },
          },
          {position})
    },
  }
}
</script>