<template>
  <div></div>
</template>

<script>

export default {

  name: "Notifications",
  computed: {
    getWorkspaceId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>