<template>
  <div>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <b-img class="w-25 mb-3"
                 fluid
                 :src="imgUrl"
                 alt="Not authorized page"/>
          <h2 class="mb-2 font-weight-bold">
            {{ $t('company.createCompany') }} 🤝
          </h2>
          <p class="mb-2">
            {{ $t('company.createCompanyDescription') }}
          </p>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    @click="$emit('openModal')"
                    class="mb-1 btn-sm-block"
                    variant="outline-primary">
            {{ $t('company.create') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {BButton, BImg} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'

export default {
  name: "Empty",
  components: {
    BImg, BButton
  },
  props: {},
  directives: {
    Ripple,
  },
  data() {
    return {
      modalShow: false,
      emptyImg: require('@/assets/images/illustration/agreement_between.svg'),
    }
  },
  computed: {
    imgUrl() {
      return this.emptyImg
    },
  }
}
</script>

<style scoped>

</style>