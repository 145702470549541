<template>
  <b-col cols="12">
    <b-form-group class="mt-1"
                  :label="$t('group.users')"
                  label-for="fh-doc-type">
      <b-row class="mb-2">
        <b-col class="w-100 text-center">
          <user-suggestion :placeholder-text="$t('group.addUser')" :workspace-id="workspaceId"
                           @select="handleUserSelection"/>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col class="w-100 text-center">
          <v-select :placeholder="$t('group.role')"
                    v-model="selectedUserMemberRole"
                    label="displayText"
                    class="w-100 v-select-no-border"
                    :options="memberRoles">
          </v-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="w-100 text-left">
          <b-form-checkbox v-model="isApprover"
                           @click.native.stop>
            {{ $t('group.chooseAsApprover') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="w-100 text-left">
          <b-form-checkbox v-model="isSigner"
                           @click.native.stop>
            {{ $t('group.chooseAsSigner') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="justify-content-end align-content-end d-flex w-100">
          <b-button @click="addUser"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    :disabled="addedUserId === null || selectedUserMemberRole === null">
            <span>{{ $t('buttons.save') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
</template>
<script>
import UserSuggestion from "@/views/Groups/UserSuggestion"
import VSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import {BButton, BCol, BFormCheckbox, BFormGroup, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {GroupEventTypes, PEventBus} from "@/services/PEventBus";

export default {
  name: 'AddGroupMember',
  components: {
    UserSuggestion,
    VSelect,
    BCol,
    BRow,
    BButton,
    BFormCheckbox,
    BFormGroup
  },
  directives: {
    Ripple,
  },
  props: {
    workspaceId: String,
    groupId: String,
  },
  data() {
    return {
      memberRoles: [
        {
          displayText: i18n.t('roles.reader'),
          value: "READER"
        },
        {
          displayText: i18n.t('roles.editor'),
          value: "EDITOR"
        }
      ],
      isApprover: false,
      isSigner: false,
      addedUserId: null,
      selectedUserMemberRole: null
    }
  },
  methods: {
    handleUserSelection(user) {
      this.addedUserId = user.id;
    },
    addUser() {
      const payload = {
        groupId: this.groupId,
        request: {
          userId: this.addedUserId,
          role: this.selectedUserMemberRole.value,
          isApprover: this.isApprover,
          isSigner: this.isSigner
        }
      }

      this.$store.dispatch('group/addUser', payload)
          .then(() => {
            PEventBus.$emit(GroupEventTypes.GROUP_UPDATED)
            this.notify(i18n.t('messages.group.groupMemberAdded'))
          })
          .catch(err => {
            this.fail(err.errorCode === 9008
                ? i18n.t('messages.group.alreadyExistsOnAnotherGroup')
                : err.errorCode === 9011
                    ? i18n.t('messages.group.groupShouldContainOneSigner')
                    : err.errorCode === 5003
                        ? i18n.t('messages.notAuthorized')
                        : i18n.t("messages.errorOccurred"))
          })

      // .
      //   catch(err => {
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: i18n.t('messages.notification'),
      //         icon: 'TrashIcon',
      //         text: err.errorCode === 9008
      //             ? i18n.t('messages.group.alreadyExistsOnAnotherGroup')
      //             : err.errorCode === 9011
      //                 ? i18n.t('messages.group.groupShouldContainOneSigner')
      //                 : i18n.t('messages.group.failedOnUserAdd'),
      //         variant: 'danger',
      //       },
      //     }, {position: 'top-center'})
      //   });
    },
    notify(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "info",
              icon: "CheckIcon",
            },
          },
          {position}
      );
    },
    fail(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "danger",
              icon: "AlertIcon",
            },
          },
          {position})
    },
  }
}
</script>