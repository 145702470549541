<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col cols="12" class="mb-2">
          {{ $t('organization.code') }}: {{ workspaceData.code }}
        </b-col>
        <b-col cols="12">
          <b-form-group
              :label="$t('organization.name')"
              label-for="fh-doc-name">
            <validation-provider
                #default="{ errors }"
                name="Title"
                vid="title"
                rules="required">
              <b-form-input
                  v-model="workspaceData.name"
                  id="fh-doc-name"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('organization.name')"/>
              <div class="flex-wrap mt-50">
                <div><small>{{ $t('organization.nameDescription') }}</small></div>
                <div>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ $t('required') }}
                  </small>
                </div>
              </div>
            </validation-provider>
          </b-form-group>
          <b-form-group
              class="mt-2"
              :label="$t('organization.description')"
              label-for="fh-doc-description">
            <validation-provider
                #default="{ errors }"
                name="Desc"
                vid="description"
                rules="required">
              <b-form-textarea
                  rows="5"
                  v-model="workspaceData.description"
                  id="fh-doc-description"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('organization.description')"/>
              <div class="flex-wrap mt-50">
                <div><small>{{ $t('organization.moreInformation') }}</small></div>
                <div><small v-if="errors.length > 0" class="text-danger">
                  {{ $t('required') }}
                </small></div>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button @click="updateWorkspace" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1">
            {{ $t('buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BRow,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {ref} from '@vue/composition-api'
import {mapState} from "vuex";
import {ValidationProvider} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {OrganizationEventTypes, PEventBus} from "@/services/PEventBus";
import i18n from "@/libs/i18n";

export default {
  name: "WorkspaceDetail",
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  computed: {
    getWorkspaceId() {
      return this.$route.params.id;
    },
    ...mapState({
      workspaceData: state => state.workspaces.organization
    })
  },
  created() {
    this.fetchWorkspace()
  },
  mounted() {
    PEventBus.$on(OrganizationEventTypes.ORGANIZATION_UPDATE_FAILED, () => this.showErrorToast())
  },
  beforeDestroy() {
    PEventBus.$off(OrganizationEventTypes.ORGANIZATION_UPDATE_FAILED, () => this.showErrorToast())
  },
  methods: {
    fetchWorkspace() {
      this.$store.dispatch('workspaces/fetchById', this.getWorkspaceId)
    },
    updateWorkspace() {
      const payload = {
        workspaceId: this.workspaceData.uid,
        request: {
          name: this.workspaceData.name,
          description: this.workspaceData.description,
        }
      }

      this.$store.dispatch('workspaces/update', payload)
          .then(() => {
            this.notify('Çalışma alanı başarılı bir şekilde güncellendi')
          })
          .catch(err => {
            this.fail(err.errorCode === 5003
                ? i18n.t('messages.notAuthorized')
                : i18n.t("messages.errorOccurred"))
          })
    },
    showErrorToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.workspace.failedOnUpdate'),
          variant: 'danger',
          icon: "AlertIcon"
        },
      }, {position: 'top-center'});
    },
    notify(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "info",
              icon: "CheckIcon",
            },
          },
          {position}
      );
    },
    fail(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "danger",
              icon: "AlertIcon",
            },
          },
          {position})
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style scoped>

</style>