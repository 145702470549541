<template>
  <validation-observer ref="inviteOrganizationMember">
    <b-form @submit.prevent>
      <b-row align-h="start">
        <b-col cols="12">
          <b-form-group class="mt-1"
                        :label="$t('organization.users')"
                        label-for="fh-doc-type">
            <b-form-input id="email"
                          v-model="mail"
                          name="email"
                          placeholder="john@example.com"/>
          </b-form-group>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="mail === '' || mail === null"
                    @click="addUser">
            {{ $t('organization.invite') }}
          </b-button>
        </b-col>
        <b-col cols="12">

          <b-list-group>
            <b-list-group-item class="flex-column align-items-start"
                               v-for="(user) in users"
                               :key="user.id">
              <div>
                <div class="d-flex w-100 justify-content-between">
                  <b-card-text class="">
                    {{ user.fullName }} | {{ user.email }}
                  </b-card-text>
                  <feather-icon icon="XCircleIcon"
                                @click="removeUser(user.id)"
                                size="30"
                                class=" cursor-pointer"/>
                </div>
                <small>
                  {{ user.email }}
                </small>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {BButton, BCol, BForm, BFormGroup, BFormInput, BListGroup, BListGroupItem, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {ValidationObserver} from 'vee-validate'
import {email, required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";

export default {
  name: "index",
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    BListGroup,
    BListGroupItem,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  props: {
    workspaceId: String
  },
  data() {
    return {
      users: [],
      organizationId: this.workspaceId,
      mail: null,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    validation() {
      return true
    },
    validationOccupation() {
      return true
    },
  },
  methods: {
    addUser() {
      const payload = {
        organizationId: this.organizationId,
        request: {
          email: this.mail
        }
      }

      this.$store.dispatch('workspaces/invite', payload)
          .then(res => {
            if (res.errorCode === 5003) {
              this.handleInvitationExceptionMessage(this.$i18n.t('messages.notAuthorized'))
            } else if (res.errorCode === 2000) {
              this.handleInvitationExceptionMessage(this.$i18n.t('messages.user.mailAlreadyInvitedToThisWorkspace'))
            } else if (res.errorCode === 2002) {
              this.handleInvitationExceptionMessage(this.$i18n.t('messages.user.mailAlreadyExistsInThisWorkspace'))
            } else if (res.errorCode === 400) {
              this.handleInvitationExceptionMessage(this.$i18n.t('messages.user.mailAddressError'))
            } else if (res.errorCode === 500) {
              this.handleInvitationExceptionMessage(this.$i18n.t('messages.user.invitationFailed'))
            } else {
              this.handleInvitationSuccessMessage()
            }
          })
    },
    removeUser(userId) {
      this.$store.dispatch('workspaces/removeUser', {organizationId: this.organizationId, userId: userId})
    },
    handleInvitationSuccessMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.user.invitationSuccess'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      }, {position: 'top-center'});

      this.fetchInvitations()
      this.$emit('close')
    },
    handleInvitationExceptionMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      }, {position: 'top-center'});
    },
    fetchInvitations() {
      this.$store.dispatch('workspaces/fetchInvitations', this.workspaceId)
    },
  }
}
</script>

<style scoped>

</style>