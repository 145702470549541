<template>
  <div>
    <loading v-if="loading"></loading>
    <div v-show="!loading && totalCompanies > 0">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12"
               md="6"
               class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="warning"
                    class="rounded-circle mr-1"
                    @click="openModal">
            <div class="icon-wrapper">
              <feather-icon icon="PlusIcon" size="21"/>
            </div>
          </b-button>
          <v-select v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mr-1"/>
        </b-col>
        <!-- Search -->
        <b-col cols="12"
               md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input debounce="2000"
                          v-model="searchQuery"
                          class="d-inline-block mr-1"
                          :placeholder="$t('search')"/>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-table ref="refCompanyListTable"
                 :items="fetchCompanies"
                 responsive
                 selectable
                 selectMode="single"
                 @row-selected="onRowSelected"
                 :small="false"
                 :fields="tableColumns"
                 primary-key="id"
                 :hover="true"
                 :head-variant="null"
                 :sort-by.sync="sortBy"
                 show-empty
                 empty-text="No matching records found"
                 :sort-desc.sync="isSortDirDesc"
                 class="position-relative font-weight-bold">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('loading') }}</strong>
            </div>
          </template>
          <template #head(companyCode)>
            <span>{{ $t('table.code') }}</span>
          </template>

          <template #head(name)>
            <span>{{ $t('table.company') }}</span>
          </template>

          <template #head(email)>
            <span>{{ $t('table.email') }}</span>
          </template>

          <template #head(phone)>
            <span>{{ $t('table.phone') }}</span>
          </template>

          <template #head(address)>
            <span>{{ $t('table.address') }}</span>
          </template>

        </b-table>
      </b-row>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              {{
                $tc('itemCountOnTable', dataMeta.from, dataMeta.to, {
                  from: dataMeta.from,
                  to: dataMeta.to
                })
              }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12"
                 sm="6"
                 class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage"
                          :total-rows="totalCompanies"
                          :per-page="perPage"
                          first-number
                          last-number
                          class="mb-0 mt-1 mt-sm-0"
                          prev-class="prev-item"
                          next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon"
                              size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon"
                              size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
    <Empty v-if="totalCompanies === 0" @openModal="openModal"/>
    <b-modal lazy
             size="lg"
             ref="create-supplier-modal"
             hide-footer
             no-close-on-backdrop
             :title="$t('company.addNew')">
      <create-or-edit-supplier :workspace-id="workspaceId"
                               :is-new="true"
                               @reload="refetchData"
                               @closeNew="closeNewModal"/>
    </b-modal>
    <b-modal lazy
             size="lg"
             ref="edit-supplier-modal"
             hide-footer
             no-close-on-backdrop
             :title="$t('company.edit')">
      <create-or-edit-supplier :workspace-id="workspaceId"
                               :supplier-data="selectedSupplierData"
                               @reload="refetchData"
                               @closeEdit="closeEditModal"/>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {BButton, BCol, BFormInput, BModal, BPagination, BRow, BSpinner, BTable} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref, toRefs} from "@vue/composition-api";
import {avatarText} from "@core/utils/filter";
import useCompaniesList from "@/views/Companies/List/useCompaniesList";
import Loading from '../../components/Loading/Loading'
import {mapState} from "vuex";
import Empty from "@/views/Companies/List/Empty";
import CreateOrEditSupplier from '@/views/Companies/Create/index'

export default {
  name: "CompanyList",
  directives: {
    Ripple
  },
  components: {
    BRow,
    BSpinner,
    Loading,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    Empty,
    CreateOrEditSupplier,
    BModal,
  },
  data() {
    return {
      selectedSupplierData: null
    }
  },
  computed: {
    ...mapState({
      loading: state => state.group.loading
    }),
  },
  props: {
    workspaceId: String
  },
  methods: {
    openModal() {
      this.$refs['create-supplier-modal'].show()
    },
    closeNewModal() {
      this.$refs['create-supplier-modal'].hide()
    },
    closeEditModal() {
      this.$refs['edit-supplier-modal'].hide()
    },
    onRowSelected(row) {
      this.selectedSupplierData = row[0]
      this.$refs['edit-supplier-modal'].show()
    }
  },
  setup(props) {

    const {workspaceId} = toRefs(props)
    const isAddNewCompanySidebarActive = ref(false)

    const roleOptions = [
      {label: 'Admin', value: 'admin'},
      {label: 'Author', value: 'author'},
      {label: 'Editor', value: 'editor'},
      {label: 'Maintainer', value: 'maintainer'},
      {label: 'Subscriber', value: 'subscriber'},
    ]

    const planOptions = [
      {label: 'Basic', value: 'basic'},
      {label: 'Company', value: 'company'},
      {label: 'Enterprise', value: 'enterprise'},
      {label: 'Team', value: 'team'},
    ]

    const statusOptions = [
      {label: 'Pending', value: 'pending'},
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'},
    ]

    const {
      fetchCompanies,
      tableColumns,
      perPage,
      currentPage,
      totalCompanies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCompanyListTable,
      workspaceIdRef,
      refetchData,

      // UI
      // resolveUserRoleVariant,
      // resolveUserRoleIcon,
      // resolveUserStatusVariant,

      // Extra Filters
      // roleFilter,
      // planFilter,
      // statusFilter,
    } = useCompaniesList(workspaceId)


    return {
      // Sidebar
      isAddNewCompanySidebarActive,
      fetchCompanies,
      tableColumns,
      perPage,
      currentPage,
      totalCompanies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCompanyListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      // resolveUserRoleVariant,
      // resolveUserRoleIcon,
      // resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      // roleFilter,
      // planFilter,
      // statusFilter,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>