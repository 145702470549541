<template>
  <validation-observer ref="supplierForm"
                       #default="{invalid}">
    <b-form @submit.prevent>
      <b-row align-h="start">
        <!-- supplier title -->
        <b-col cols="6">
          <b-form-group :label="$t('company.setSupplier')"
                        label-for="supplier-name">
            <validation-provider #default="{ errors }"
                                 name="SupplierName"
                                 vid="name"
                                 rules="required">
              <b-form-input id="supplier-name"
                            v-model="supplierName"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('company.name')"/>
              <small v-if="errors.length > 0" class="text-danger">
                {{ $t('required') }}
              </small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- supplier email -->
        <b-col cols="6">
          <b-form-group :label="$t('company.emailWhoSupplierSignatory')"
                        label-for="supplier-email">
            <validation-provider #default="{ errors }"
                                 name="SupplierEmail"
                                 vid="email"
                                 rules="required">
              <b-form-input id="supplier-email"
                            v-model="supplierEmail"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('company.responsibleEmail')"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ $t('messages.company.emailRequired') }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- supplier phone -->
        <b-col cols="6">
          <b-form-group :label="$t('company.phoneNumber')"
                        label-for="supplier-phone">
            <validation-provider #default="{ errors }"
                                 name="SupplierPhone"
                                 vid="phone"
                                 rules="required">
              <b-form-input id="supplier-phone"
                            v-model="supplierPhone"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('company.phone')"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ $t('messages.company.phoneRequired') }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- supplier tax no -->
        <b-col cols="6">
          <b-form-group :label="$t('company.taxNumber')"
                        label-for="supplier-tax">
            <validation-provider #default="{ errors }"
                                 name="SupplierTax"
                                 vid="tax"
                                 rules="required">
              <b-form-input id="supplier-tax"
                            v-model="supplierTax"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('company.setTaxNumber')"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ $t('messages.company.taxRequired') }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- supplier mersis -->
        <b-col cols="6">
          <b-form-group :label="$t('company.mersisNumber')"
                        label-for="supplier-mersis">
            <validation-provider #default="{ errors }"
                                 name="SupplierMersis"
                                 vid="mersis"
                                 rules="required">
              <b-form-input id="supplier-mersis"
                            v-model="supplierMersis"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('company.setMersisNumber')"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ $t('messages.company.mersisRequired') }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- supplier registry no -->
        <b-col cols="6">
          <b-form-group :label="$t('company.registryNumber')"
                        label-for="supplier-registry">
            <validation-provider #default="{ errors }"
                                 name="SupplierRegistry"
                                 vid="registry"
                                 rules="required">
              <b-form-input id="supplier-registry"
                            v-model="supplierRegistry"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('company.setRegistryNumber')"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ $t('messages.company.registryRequired') }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- supplier address -->
        <b-col cols="12">
          <b-form-group :label="$t('company.address')"
                        label-for="supplier-address">
            <validation-provider #default="{ errors }"
                                 name="SupplierAddress"
                                 vid="address"
                                 rules="required">
              <b-form-textarea rows="3"
                               id="supplier-address"
                               v-model="supplierAddress"
                               :state="errors.length > 0 ? false:null"
                               :placeholder="$t('company.setAddress')"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ $t('messages.company.addressRequired') }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <br/>
          <b-row align-h="end" class="px-1">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      @click="save"
                      type="submit"
                      :disabled="invalid"
                      variant="primary">
              {{ $t('buttons.save') }}
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BRow} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {email, required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";

export default {
  name: "index",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BForm,
    BButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isNew: Boolean,
    workspaceId: String,
    supplierData: Object,
  },
  watch: {
    supplierData: {
      immediate: true,
      handler() {
        if (this.supplierData !== null) {
          this.supplierName = this.supplierData.name
          this.supplierEmail = this.supplierData.email
          this.supplierPhone = this.supplierData.phone
          this.supplierTax = this.supplierData.accountNumber
          this.supplierMersis = this.supplierData.mersisNumber
          this.supplierRegistry = this.supplierData.registryNumber
          this.supplierAddress = this.supplierData.address
        }
      }
    }
  },
  mounted() {
  },
  computed: {
    validation() {
      return this.userId.length > 4 && this.userId.length < 13
    },
    validationOccupation() {
      return this.occupationID.length > 1
    },
  },
  data: () => ({
    occupationID: '',
    supplierName: null,
    supplierEmail: null,
    supplierPhone: null,
    supplierTax: null,
    supplierMersis: null,
    supplierRegistry: null,
    supplierAddress: null,
    invalid: true,
    required,
    email,
  }),
  methods: {
    save() {
      this.$refs.supplierForm.validate()
          .then(success => {
            if (success) {

              const payload = {
                name: this.supplierName,
                phone: this.supplierPhone,
                email: this.supplierEmail,
                mersisNumber: this.supplierMersis,
                registryNumber: this.supplierRegistry,
                address: this.supplierAddress,
                accountNumber: this.supplierTax,
                workspaceId: this.workspaceId
              }

              if (this.isNew) {
                this.$store.dispatch('company/create', payload)
                    .then(value => {
                      this.showSuccessToast()
                      this.$emit('reload')
                      this.$emit('closeNew')
                    })
                    .catch(err => {
                      this.showErrorToast()
                    })
              } else {
                payload['companyId'] = this.supplierData.id
                this.$store.dispatch('company/update', payload)
                    .then(value => {
                      this.showSuccessToast()
                      this.$emit('reload')
                      this.$emit('closeEdit')
                    })
                    .catch(err => this.showErrorToast())
              }
            }
          })
    },
    showSuccessToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.workspace.companyAdded'),
          variant: 'info',
          icon: "CheckIcon"
        },
      }, {position: 'top-center'});
    },
    showErrorToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.errorOccurred'),
          variant: 'danger',
          icon: "AlertIcon"
        },
      }, {position: 'top-center'});
    },
  }
}
</script>

<style scoped>

</style>